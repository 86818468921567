<template>
  <Modal
    id="modal_licences_supp"
    element-to-focus="input"
  >
    <template #title>
      Demande de licences enseignant supplémentaires
    </template>
    <template #subtitle>
      Vous avez sélectionné des licences élèves pour lesquelles des gratuités enseignant seront fournies.
      Si le nombre de licences enseignant n'est pas suffisant, vous pouvez ajuster le total pour chaque
      licence ci-dessous afin de faire la demande aux éditeurs.
    </template>

    <template #content>
      <TableClassic
        :rows="rows"
        :columns="columns"
        @sort="$emit('sort', $event)"
      >
        <template
          #nb_ens_necessaire="{ value, }"
        >
          <InputQuantity
            v-model="value.nb_ens_necessaire"
            :min="0"
            :max="100"
            @click.native.stop.prevent=""
            @change="
              value._children = value.nb_ens_necessaire > value.gratuite_ens ? [{}, ] : null;
              value._expanded = value._children && value._children.length;
            "
          />
        </template>
        <template
          #child-row="{ value, }"
        >
          <div
            v-if="value.nb_ens_necessaire > value.gratuite_ens"
            class="minima"
          >
            <p class="text-medium">
              {{ $t('ask-licence.text-raison-licence-supp') }}{{ "\xa0" }}:
            </p>
            <ButtonToggle
              v-model="value.raison"
              name="effectif_reduit"
              input-value="effectif_reduit"
              :label="$t('ask-licence.raison-effectif')"
            />
            <ButtonToggle
              v-model="value.raison"
              name="plsr_enseignants"
              input-value="plsr_enseignants"
              :label="$t('ask-licence.raison-plsr-enseignants')"
            />
            <ButtonToggle
              v-model="value.raison"
              name="handicap"
              input-value="handicap"
              :label="$t('ask-licence.raison-handicap')"
            />
          </div>
        </template>
      </TableClassic>
    </template>

    <template #footer>
      <ButtonClassic
        variant="solid"
        label="Valider"
        color="primary"
        icon="right"
        :disabled="disabled"
        @click="submit()"
      >
        <template #right-icon>
          <UilCheckCircle />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import {
  ButtonClassic, Modal, InputQuantity, ButtonToggle,
} from "@lde/core_lde_vue";

import { UilCheckCircle } from "@iconscout/vue-unicons";
import Api from "@/modules/axios";
import TableClassic from "@/components/table/TableClassic.vue";

/**
   * Modale affichée lors de la création d'un devis ou cmd pour antares
   */
export default {
  name: "ModalLicencesSupp",
  components: {
    Modal,
    ButtonClassic,
    TableClassic,
    UilCheckCircle,
    InputQuantity,
    ButtonToggle,
  },
  props: {
    lignes: {
      type: Array,
      required: true,
    },
  },
  emits: [
  ],
  data() {
    return {
      columns: [
        {
          key: "reference",
          name: this.$t("liste.reference"),
        },
        {
          key: "titre",
          name: this.$t("general.titre"),
        },
        {
          key: "quantite_elv",
          name: this.$t("general.quantite-licence-eleve"),
        },
        {
          key: "gratuite_ens",
          name: this.$t("liste.nombre-gratuites-ens-fournies"),
        },
        {
          key: "nb_ens_necessaire",
          name: this.$t("liste.total-licence-ens-necessaires"),
        },
      ],
      rows: [],
    };
  },
  computed: {
    disabled() {
      return this.rows.some((row) => row.nb_ens_necessaire > row.gratuite_ens && !row.raison);
    },
  },
  mounted() {
  },
  methods: {
    open() {
      const id = this.$route.params.id || this.$store.getters.panier.id;
      return Api().get(`/liste/${id}/gratuites`).then((response) => {
        const rows = this.lignes.filter(
          (ligne) => ligne.offre.manuel_numerique,
        ).map((ligne) => ({
          id: ligne.id,
          ean_lde: ligne.offre.ean_lde,
          reference: ligne.offre.manuel_numerique.ean,
          titre: ligne.offre.manuel_numerique.libelle,
          quantite_elv: ligne.quantite,
          gratuite_ens: "?",
          nb_ens_necessaire: "?",
          _children: null,
          _expanded: true,
        }));

        response.data.res.forEach((gratuite) => {
          if (gratuite.data.length === 0) {
            return;
          }

          const ligne = rows.find((row) => `${row.id}` === `${gratuite.id}`);
          if (ligne) {
            ligne.gratuite_ens = gratuite.data[0].qte_gratuite;
            ligne.nb_ens_necessaire = gratuite.data[0].qte_gratuite;
            ligne.ref_gratuite = gratuite.data[0].ref;
            ligne.ean_gratuite = gratuite.data[0].ean_gra;
          }
        });

        this.rows = rows.filter((row) => row.gratuite_ens !== "?");

        if (this.rows.length > 0) {
          this.$modal.show("modal_licences_supp");
        } else {
          this.$emit("submit", []);
        }
      });
    },
    submit() {
      this.$emit("submit", this.rows.filter((row) => row.nb_ens_necessaire > row.gratuite_ens));
    },
    hide() {
      this.$modal.hide("modal_license_supp");
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;
@use "~@lde/core_lde_vue/dist/assets/styles/_fonts.scss" as font;

#modal_licences_supp {
  .separator {
    height: var.$space-x-tiny;
  }
  .table-classic-new {
    font-size: var.$font-medium;
  }

  .table-classic-new {
    position: relative;
    z-index: 999;
    background: white;
  }

  th, td {
    white-space: wrap;
    &.quantite_elv, &.gratuite_ens, &.nb_ens_necessaire {
      text-align: center;
    }
  }
  .child-row td {
    padding: var.$space-tiny;
    .minima {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: var.$gray-medium;
      .button-toggle { padding: 1rem; }
      .label {
        color: var.$gray-medium;
        @include font.text-medium;
      }
    }
  }
}
</style>
